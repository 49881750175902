import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { useMeta, useContentfulSection } from "utils"

import { PrimaryNav, AppStickyNav, MobileNav } from "src/components/navigation"
import { DocumentsCompareTable } from "src/components/tables"

import { Layout, Seo } from "components/common"

import AppCompareTemplate from "src/templates/AppCompareTemplate.js"

const DocumentsComparePage = () => {
  const { documents, app_doc, page } = useStaticQuery(query)

  const meta = useMeta(page)

  const { documents_compare_hero } = useContentfulSection(page.sections)
  const slug = "documents"

  return (
    <StyledComparePage>
      <Layout>
        <Seo {...meta} />

        <MobileNav slug={slug} />
        <PrimaryNav slug={slug} />
        <div className="nav-background">
          <AppStickyNav
            slug={slug}
            salesforceAppexchangeUrl={app_doc.salesforceAppexchangeUrl}
          />
        </div>
        <AppCompareTemplate
          section={documents_compare_hero}
          app={app_doc}
          slug={slug}
          table={() => (
            <DocumentsCompareTable
              slug={slug}
              table={documents}
              switcherTitle="Compare Documents by Opero to:"
            />
          )}
        />
      </Layout>
    </StyledComparePage>
  )
}

const StyledComparePage = styled.div`
  .nav-background {
    --section-background-color: none;
  }
`

export const query = graphql`
  {
    documents: allGoogleSpreadsheetDocuments {
      edges {
        node {
          id
          # num
          feature
          opero
          nintex
          congaComposer
        }
      }
    }
    page: contentfulPage(pageId: { eq: "documents_compare" }) {
      ...Page
    }
    app_doc: contentfulApp(slug: { eq: "documents" }) {
      ...App
    }
  }
`

export default DocumentsComparePage
